import { chakra, shouldForwardProp, Center, Icon, Box } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import { ReactNode } from "react";
import { FaStar } from "react-icons/fa";

const ChakraIcon = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});


const Pulsate: React.FC<{ children: ReactNode, scale?: number[], duration?: number }> = ({ children, scale = [1, 1.3, 1, 1.3, 1], duration = 4 }) => {
    return (
        <Box>
            <ChakraIcon
                animate={{ scale }}
                transition={{
                    duration,
                    // @ts-ignore
                    repeat: Infinity,
                    repeatType: "loop",
                }}
            >
                {children ? children : (
                    <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                        <Icon as={FaStar} color={'teal.700'} fontSize={24} _hover={{ color: 'teal.800' }} />
                    </Center>
                )}
            </ChakraIcon>
        </Box>

    )
}

export default Pulsate