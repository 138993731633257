import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useMediaQuery, Box, Stack, Text, InputGroup, Button, Textarea, Alert, AlertIcon } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

interface OfferMessageModal {
    isOpen: boolean;
    onClose: () => void;
}

const OfferMessageModal: React.FC<OfferMessageModal> = ({ isOpen, onClose }) => {
    const { register } = useFormContext();
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    function saveMessage() {
        onClose();
    }

    const renderModalBody = () => {
        return (
            <Box pb={4}>
                <Stack spacing={4}>
                    <Box>
                        <Text fontSize={12}>Skriv ett meddelande till kund</Text>
                        <InputGroup>
                            <Textarea borderRadius={8} {...register('message')} minLength={5} maxLength={500} autoFocus minH={32} placeholder="Meddelande..." size={'sm'} />
                        </InputGroup>
                    </Box>
                    <Stack>
                        <Alert status='info' fontSize={12} borderRadius={12}>
                            <AlertIcon />
                            OBS! Kunden har inte möjlighet att svara på ditt meddelande i dagsläget men får möjlighet att ta rätt beslut tack vare ditt meddelande. 
                        </Alert>

                    </Stack>

                    <Button
                        mt={4}
                        w="140px"
                        variant='primary'
                        size="sm"
                        onClick={saveMessage}
                    >
                        Fortsätt
                    </Button>
                </Stack>
            </Box >
        )
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => {
                onClose()
            }} size={isMobile ? 'full' : 'md'}>
                <ModalOverlay />
                <ModalContent borderRadius={20}>
                    <ModalHeader fontSize={16}>{'Nytt meddelande'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderModalBody()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default OfferMessageModal;