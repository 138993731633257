import React, { useRef } from "react";
import { Box, Text, Stack, HStack, Icon, Badge, useMediaQuery, Button, useDisclosure, Center, Tooltip, SkeletonCircle, SkeletonText, Divider, Heading } from "@chakra-ui/react";
import 'moment/locale/sv'
import moment from "moment";
import Reparations from "./Reparations";
import Service from "./Service";
import { FaChevronRight, FaMapMarkerAlt } from "react-icons/fa";
import LicensePlate from "../LicensePlate";
import OfferModal from "./OfferModal";
import isEmpty from 'lodash/isEmpty';
import { useHandledOfferRequests, UseOfferRequests } from "../../hooks/api/offerRequests";
import noDataImage from '../../common/undraw_happy_music_g6wc.svg';

const mapAssignStatus = {
    'assigned_to_you': {
        assignStatusColor: 'green',
        assignStatusLabel: 'Kunden har accepterat din offert'
    },
    'assigned_to_other': {
        assignStatusColor: 'red',
        assignStatusLabel: 'Kunden har avböjt'
    },
    'unassigned': {
        assignStatusColor: 'blue',
        assignStatusLabel: 'Väntar på svar från kund'
    },
}

const HandledEnquiries: React.FC = () => {
    const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')
    const [isMobile] = useMediaQuery('(max-width: 480px)')

    const { offerRequests: enquiries, isLoading, isError }: UseOfferRequests = useHandledOfferRequests();
    const { onClose, onOpen, isOpen } = useDisclosure();
    const selectedOfferRef = useRef(null);


    if (isError) {
        return null;
    }

    if (isLoading) {
        return (
            <Stack spacing={4} margin={'0 auto'}>
                <Box padding='6' bg='white' height={400} borderRadius={16} border={'1px solid'} borderColor={'gray.300'}>
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={8} spacing='6' />
                </Box>
                <Box padding='6' bg='white' height={400} borderRadius={16} border={'1px solid'} borderColor={'gray.300'}>
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={8} spacing='6' />
                </Box>
                <Box padding='6' bg='white' height={400} borderRadius={16} border={'1px solid'} borderColor={'gray.300'}>
                    <SkeletonCircle size='10' />
                    <SkeletonText mt='6' noOfLines={8} spacing='6' />
                </Box>
            </Stack>
        )
    }

    return (
        <Box>
            <OfferModal onClose={onClose} isOpen={isOpen} offer={selectedOfferRef.current?.offer} />
            {enquiries.filter(enq => enq)?.length === 0 && (
                <Center height={96} mt={[8, 10, 20, 12]} pb={12}>
                    <Stack spacing={12} alignItems={'center'}>
                        <Box textAlign={'center'}>
                            <Heading size={'sm'}>Du har inga behandlade offertförfrågningar</Heading>
                            <Text fontSize={14}>Här kommer dem att dyka upp när du skickar offerter</Text>
                        </Box>
                        <img src={noDataImage} width={260} alt="bild som visas när data saknas" />
                    </Stack>
                </Center>
            )}
            {enquiries?.map((offerReq, i) => {
                const isDirectedEnquiry = !isEmpty(offerReq?.directedCompanyId);
                const { assignStatusColor, assignStatusLabel } = mapAssignStatus[offerReq.assignedStatus];
                const isLastItem = i === enquiries.length - 1;

                return (
                    <>
                        <Box borderLeft={isDirectedEnquiry ? '4px solid' : 'none'} borderLeftColor={'green.500'} borderBottomRadius={isLastItem ? 16 : 0} position={'relative'} key={`${offerReq.createdAt}-${offerReq.regNo}`} margin={'0 auto'} bg="gray.50">
                            <Box padding={2}>
                                <Badge pos={'absolute'} top={0} right={0} fontSize={11} px={2} py={1} colorScheme={assignStatusColor}>{assignStatusLabel}</Badge>
                                <Stack padding={4} pb={0} spacing={4}>
                                    <HStack align='start' w={'100%'} justifyContent={'space-between'}>
                                        <Stack spacing={4} >
                                            {offerReq?.reparations?.length > 1 ? (
                                                <Stack spacing={3}>
                                                    <Service enquiry={offerReq} />
                                                    <Reparations enquiry={offerReq} />
                                                </Stack>
                                            ) : (
                                                <Stack spacing={3}>
                                                    <Service enquiry={offerReq} />
                                                    <Reparations enquiry={offerReq} />
                                                </Stack>
                                            )}
                                            {offerReq.comment && (
                                                <Stack pt={2}>
                                                    <Heading size={'xs'}>{'Övrig kommentar från kund'}</Heading>
                                                    <Text fontSize={14}>{offerReq.comment}</Text>
                                                </Stack>
                                            )}
                                        </Stack>
                                        <Stack spacing={4} w={['100%', '100%', '60%', '60%']}>
                                            {!isLargeScreen && (
                                                <HStack alignItems="start" justifyContent="space-between" paddingRight={2}>
                                                    <LicensePlate regNo={offerReq.regNo} size={'xs'} />
                                                    {isMobile && (
                                                        <Text fontSize={10}>Hanterad {moment(offerReq.offer.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                    )}
                                                </HStack>
                                            )}
                                        </Stack>
                                        {!isMobile && (
                                            <Stack pt={2} alignItems='flex-end'>
                                                <Text w={'max-content'} fontSize={10}>Hanterad {moment(offerReq.offer.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                {isLargeScreen && (
                                                    <LicensePlate size="xs" regNo={offerReq.regNo} />
                                                )}
                                                <Stack>
                                                    <HStack alignItems="center">
                                                        <Icon as={FaMapMarkerAlt} />
                                                        <Text fontSize={12}>{offerReq.distance} km ifrån</Text>
                                                    </HStack>
                                                </Stack>
                                            </Stack>
                                        )}
                                    </HStack>
                                    <HStack justify={'space-between'} align={'center'}>

                                        <Box pt={2} pb={2}>
                                            <Stack align={'flex-start'} maxW={80}>
                                                <Button size={'sm'} onClick={() => {
                                                    selectedOfferRef.current = offerReq;
                                                    onOpen()
                                                }} variant={'link'} rightIcon={<Icon as={FaChevronRight} />} colorScheme={'blue'}>Visa skickad offert</Button>
                                            </Stack>
                                        </Box>

                                        {isDirectedEnquiry && !isMobile && (
                                            <Tooltip
                                                hasArrow
                                                bg='teal.600'
                                                rounded={12}
                                                color='white'
                                                placement='top'
                                                label={'Den här offertenförfrågan är skickad av kunden till endast dig.'}
                                            >
                                                <Badge cursor={'pointer'} fontSize={12} px={2} py={.5} rounded={'full'} colorScheme={'green'}>{'Riktad förfrågan till dig'}</Badge>
                                            </Tooltip>
                                        )}
                                    </HStack>
                                </Stack>
                            </Box>
                        </Box>
                        {!isLastItem && (
                            <Divider />
                        )}
                    </>
                )
            })}
        </Box>
    )

}

export default HandledEnquiries;