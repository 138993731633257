import React, { useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import '../DrawerContent/CreateBookingForm.scss';
import { useCompanyServices } from '../../hooks/api/companyInfo';
import { Text, Stack, useMediaQuery } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

export function getCustomStyles(isMobile: boolean): StylesConfig {
    return {
        // @ts-ignore
        indicatorSeparator: () => { }, // removes the "stick"
        dropdownIndicator: defaultStyles => ({
            ...defaultStyles,
        }),
        menuList: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: isMobile ? 11 : 16,
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: isMobile ? 11 : 16,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            lineHeight: 1.8,
            fontSize: isMobile ? 10 : 14,
            maxHeight: isMobile ? 32 : 'auto'
        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            lineHeight: 1.8,
            fontSize: isMobile ? 10 : 16,
        }),
        control: base => ({
            ...base,
            height: '3rem',
            minHeight: '3rem',
            borderRadius: 6,
            borderColor: '#E2E8F0'
        })
    }
}

interface WorkArticleSelectProps {
    handleInputChange: (val: string) => void;
    resetKey?: number;
    placeholder: string; resetArticleSelect?: boolean;
    setResetArticleSelect: (val: boolean) => void;
    disabled: boolean;
}

export const WorkArticleSelect: React.FC<WorkArticleSelectProps> = ({ handleInputChange, resetKey, placeholder, resetArticleSelect, setResetArticleSelect, disabled }) => {
    const { companyServices } = useCompanyServices();
    const [, rerender] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const { control, formState: { errors } } = useFormContext();
    const articles = companyServices.savedArticles;
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    useEffect(() => {
        if (resetArticleSelect) {
            setSelectedOption(null)
            handleInputChange(null);
            setResetArticleSelect(false)
        }
    }, [resetArticleSelect, setSelectedOption])

    const styles = getCustomStyles(isMobile);
    return (
        <Controller
            name="workArticleSelect"
            control={control}
            render={({ field }) => (
                <Stack pos={'relative'} w={'100%'} align='flex-start' spacing={1} data-testid="article-description-field">
                    {errors?.workArticleSelect?.message && (
                        <Text pos={'absolute'} top={-4} zIndex={99999} fontSize={10} color={'red.500'}>
                            {errors?.workArticleSelect?.message as string}
                        </Text>
                    )}
                    <Select
                        isDisabled={disabled}
                        autoFocus
                        {...field}
                        ariaLiveMessages={{
                            guidance: () => '',
                            onChange: () => '',
                            onFilter: () => '',
                            onFocus: () => ''
                        }}
                        key={resetKey}
                        value={selectedOption ? { label: selectedOption, value: selectedOption } : null}
                        openMenuOnClick={false}
                        name="workArticleSelect"
                        onInputChange={(inputVal) => {
                            if (inputVal && inputVal.length > 0) {
                                field.onChange(inputVal);
                                handleInputChange(inputVal);
                                setSelectedOption(inputVal);
                            }
                            rerender({});
                        }}
                        onChange={(selected) => {
                            if (selected) {
                                handleInputChange(selected.value);
                                setSelectedOption(selected.value);
                                field.onChange(selected.value);
                            } else {
                                handleInputChange(null);
                                setSelectedOption('');
                                field.onChange('');
                            }
                        }}
                        styles={styles as any}
                        noOptionsMessage={(obj) => (
                            <Text fontSize={[10, 12]}>{`Reservdel "${obj?.inputValue}" sparas ner automatiskt i listan till nästa gång`}</Text>
                        )}
                        isClearable
                        options={articles}
                        placeholder={placeholder}
                        className="basic-multi-select custom-service-select"
                        classNamePrefix="select"
                    />
                </Stack>
            )}
        />

    )
}