import { Badge, Box, Button, Divider, Grid, GridItem, Heading, HStack, Icon, Stack, Text, Tooltip, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BaseLayout from "./BaseLayout";
import { FaArrowLeft, FaDollarSign, FaRegFilePdf } from "react-icons/fa";
import Activities from "../components/Bookings/Activities";
import { axiosPut } from "../hooks/api/utils";
import { useCustomToast } from "../hooks/useCustomToast";
import { getFirebaseImageUrl } from "../utils/firebase";
import CancelBookingModal from "../components/modals/CancelBooking";
import { getSourceType } from "../common/utils";
import moment from "moment";
import { useAcceptedOffer, useBookingArticleSummary, useMessaging, useServiceMessagesV2 } from "../hooks/api/booking";
import { getCategories } from "../components/Bookings/WorkCategoryUtils";
import { Spinner } from "../components/Spinner";
import WorkArticleDetails from "../components/WorkArticleDetails";
import { useNotificationLogResult } from "../hooks/api/notificationLog";

const ResponsiveContentWrapper = ({ children }) => {
    const [isLargerThan1128] = useMediaQuery('(min-width: 1128px)')

    if (isLargerThan1128) {
        return (
            <Grid templateColumns='repeat(6, 1fr)' gap={4}>
                {children}
            </Grid>
        )
    }

    return (
        <Stack padding={2}>
            {children}
        </Stack>
    )
}

const BookingDetails: React.FC<{}> = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoadingCancelBooking, setIsLoadingCancelBooking] = useState(false);
    const offerResult = useAcceptedOffer(state['offerEnquiryId'], state['bookingId'])
    const offerId = state['offerId'];
    const messagesResponse = useMessaging(offerId)
    const messages = messagesResponse.data;

    const bookingId = state['bookingSource'] === 'platform' ? state['bookingId'] : null
    const bookingArticleSummaryResult = useBookingArticleSummary(bookingId)
    const additionalOfferMessageResult = useServiceMessagesV2(bookingId)
    const notificationDetails = useNotificationLogResult(bookingId)
    const createBookingNotifications = notificationDetails?.notificationData?.find(data => data.source == 'platform.create.booking');
    const finishBookingNotifications = notificationDetails?.notificationData?.find(data => data.source == 'platform.finish.booking');

    //const { carInfo } = useCarInfo(state['regNo']);
    const { successToast, errorToast } = useCustomToast();

    useEffect(() => {
        // Updates data when navigating to this page with "updateData"
        if (state['updateData']) {
            offerResult.mutate()
            bookingArticleSummaryResult.mutate()
            additionalOfferMessageResult.mutate();
        }
    }, [state])

    const status = state['status'];

    if (!state) {
        return (
            <div>Inget att visa</div>
        )
    }

    async function handleCancelBooking() {
        try {
            setIsLoadingCancelBooking(true)
            await axiosPut(`/api/bookings/bookings-v1/${state['bookingId']}/status/canceled`)
            setIsLoadingCancelBooking(false)
            successToast('Avbokningen har nu genomförts')
            onClose()
        } catch (error) {
            setIsLoadingCancelBooking(false)
            return errorToast('Något gick fel när du försökte avboka, försök igen snart eller ring vår support')
        }
    }

    function renderItemRow(label: string, value: string) {
        return (
            <HStack justifyContent={'space-between'}>
                <Text color="gray.500" fontSize="sm">{label}</Text>
                <Text color="gray.700" fontSize="sm">{value || 'Uppgift saknas'}</Text>
            </HStack>
        )
    }


    const acceptedTimeThreshold = state['acceptedTime']?.split('-')[1]
    const currentTimeLaterThanAcceptedTime = moment(new Date()).format('HH:mm') > moment(acceptedTimeThreshold, 'HH:mm').add(1, 'hours').format('HH:mm');
    const disableCancelBookingForOngoingBookings = status.key === 'waiting' && moment(new Date()).format('YYYY-MM-DD') >= state['acceptedDate'] && currentTimeLaterThanAcceptedTime;

    // Values that are to be displayed - We either get the offerResult if it's an app-offer or from bookingArticleSummaryResult if manually created booking
    // The same payload is respected for both by backend
    const totalPricingSum = offerResult?.data?.totalPricingSum || bookingArticleSummaryResult?.data?.totalPricingSum;
    const additionalWorkOffers = offerResult?.data?.additonalWorkOffers || bookingArticleSummaryResult?.data?.additonalWorkOffers;
    const originalOffer = offerResult?.data?.originalOffer || bookingArticleSummaryResult?.data?.originalOffer;

    function renderWorkDetails() {
        // Offer is used when it's pricing details from an offer, otherwise when booking created via platform, use bookingArticleSummaryResult

        if (state['bookingSource'] === 'mobile_app' && originalOffer?.isOfferByFormValues) {
            return (
                <WorkArticleDetails data={originalOffer} />
            )
        }

        if (state['bookingSource'] === 'platform' && originalOffer?.isOfferByFormValues) {
            return (
                <WorkArticleDetails data={originalOffer} />
            )
        }

        const isSingleTroubleshootEnquiry = state['reparations'] && state['reparations']?.length === 1 && !state['service'] && state['reparations']?.some(rep => rep.category === 'troubleshoot')

        if (isSingleTroubleshootEnquiry) {
            // Visa arbetskostnader
            return (
                <WorkArticleDetails data={originalOffer} onlyLaborSection />
            )
        }

        if (!offerResult.data) {
            return (
                <Stack>
                    <HStack justify={'space-between'}>
                        <Heading size={'xs'}>{'Totalt pris'}</Heading>
                        <Heading size={'xs'}>{state['price']}</Heading>
                    </HStack>
                    <Text fontSize={12}>{'Priset avser totalpriset från arbetsdetaljerna i filen'}</Text>
                </Stack>
            )
        }
        // TODO: Fixa att detta inte syns vid felsökning. Visas i dagsläget vid felsökning men borde vara att den visar arbetskostanad istället
    }

    function renderAdditionalWorkDetails() {
        return (
            <Stack spacing={12}>
                {additionalWorkOffers.map((offer, i) => (
                    <Stack>
                        <WorkArticleDetails data={offer} />
                        {i !== additionalWorkOffers.length - 1 && (
                            <Divider borderColor={'gray.400'} pt={8} />
                        )}
                    </Stack>
                ))}
            </Stack>
        )
    }

    return (
        <BaseLayout>
            <CancelBookingModal handleCancelBooking={handleCancelBooking} isLoadingCancelBooking={isLoadingCancelBooking} isOpen={isOpen} onClose={onClose} />

            <HStack onClick={() => navigate(-1)} cursor="pointer" align={'center'} p={2} spacing={2} mb={[2, 2, 6, 6]} w={'fit-content'}>
                <Icon as={FaArrowLeft} />
                <Text>Tillbaka</Text>
            </HStack>

            <ResponsiveContentWrapper>
                <GridItem colSpan={3} pb={2}>
                    <Box
                        background="white" w="100%" h="auto" boxShadow="md" borderRadius={'2xl'} p={2}>
                        <Box padding="6">
                            <HStack justifyContent={'space-between'}>
                                <Box w={'100%'}>
                                    <HStack justify={'space-between'}>
                                        <Text color="gray.700" fontSize={['md', 'md', 'lg', 'lg']} fontWeight="semibold">Bokning för {state['regNo']}</Text>
                                        <Tooltip
                                            hasArrow
                                            bg='teal.700'
                                            color='white'
                                            placement='top'
                                            label={disableCancelBookingForOngoingBookings ? 'Du kan inte längre avboka det här besöket eftersom besöket redan ska vara påbörjat. Har du missat att avboka tidigare? Kontakt oss på BilGo så hjälper vi dig.' : ''}>
                                            <Stack >
                                                <Button isDisabled={disableCancelBookingForOngoingBookings || status.key === 'canceled' || status.key === 'finished'} variant={'link'} colorScheme={'teal'} size={'sm'} onClick={() => onOpen()}>Avboka</Button>
                                            </Stack>
                                        </Tooltip>
                                    </HStack>
                                    <Box>
                                        <Badge colorScheme={getSourceType(state['bookingSource']).badgeColor}>{getSourceType(state['bookingSource']).label}</Badge>
                                    </Box>
                                </Box>
                            </HStack>
                        </Box>
                        <Divider />
                        <Stack spacing={6} padding="4">
                            <Stack pt={2} justifyContent={'space-between'}>
                                <Heading size={'sm'}>Viktiga datum</Heading>
                                <HStack fontSize={14} justifyContent={'space-between'}>
                                    <Text color="gray.500">Inlämningsdatum</Text>
                                    <Text ml={1} fontSize={12} display={'inline-block'} bg='teal.700' color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{moment(state['acceptedDate']).format('LL')}</Text>
                                </HStack>
                                <HStack fontSize={14} justifyContent={'space-between'}>
                                    <Text color="gray.500">Förväntat upphämtningsdatum</Text>
                                    <Text ml={1} fontSize={12} display={'inline-block'} bg='teal.700' color="white" borderRadius={'2xl'} px='1.5' py='.5' mr={2}>{state['updatedAcceptedDate'] ? moment(state['updatedAcceptedDate']).format('LL') : moment(state['pickupDate']).format('LL') || 'Saknas'}</Text>
                                </HStack>
                            </Stack>
                            <Divider />
                            <Stack>
                                <Heading size={'sm'}>Biluppgifter</Heading>
                                {renderItemRow('Registreringsnummer', state['regNo'])}
                            </Stack>
                            <Divider />
                            <Stack>
                                <Heading size={'sm'}>Kundens kontaktuppgifter</Heading>
                                {renderItemRow('Namn', state['fullName'])}
                                {renderItemRow('E-post', state['email'])}
                                {renderItemRow('Telefonnummer', state['phoneNo'])}
                            </Stack>
                            <Divider />
                            <Stack>
                                <Heading size={'sm'}>Notifieringar</Heading>
                                {state['bookingSource'] === 'platform' && (
                                    <>
                                        {renderItemRow('Sms - bokningsbekräftelse', createBookingNotifications?.sentSms ? 'Skickat' : 'Inte skickat')}
                                        {renderItemRow('Epost - bokningsbekräftelse', createBookingNotifications?.sentEmail ? 'Skickat' : 'Inte skickat')}
                                        {renderItemRow('Sms - avsluta arbete', finishBookingNotifications?.sentSms ? 'Skickat' : 'Inte skickat')}
                                        {renderItemRow('Epost - avsluta arbete', finishBookingNotifications?.sentEmail ? 'Skickat' : 'Inte skickat')}
                                    </>
                                )}
                                {state['bookingSource'] === 'mobile_app' && (
                                    <>
                                        {renderItemRow('Epost - avsluta arbete', finishBookingNotifications?.sentEmail ? 'Skickat' : 'Inte skickat')}
                                        {renderItemRow('Pushnotiser i appen', 'Skickat automatiskt')}
                                    </>

                                )}
                            </Stack>
                            <Divider />
                            {messages?.length > 0 && (
                                <>
                                    <Stack>
                                        <Heading size={'sm'}>Meddelanden</Heading>
                                        <HStack align={'start'}>
                                            <Text p={1.5} px={3} bg={'gray.100'} borderRadius={12} fontSize={14} whiteSpace={'pre-wrap'}>{messages[0].content}</Text>
                                        </HStack>
                                    </Stack>
                                    <Divider />
                                </>
                            )}

                            {state['bookingSource'] === 'platform' && (
                                <>
                                    <Stack>
                                        <Heading size={'sm'}>Egen anteckning</Heading>
                                        <Text fontSize={14}>{state['description'] || 'Du har inga egna anteckningar'}</Text>
                                    </Stack>
                                    <Divider />
                                </>
                            )}

                            {state['bookingSource'] === 'mobile_app' && (
                                <Stack>
                                    <Heading size={'sm'}>Kundens förfrågan</Heading>
                                    <Box bg={'gray.100'} borderRadius={16} width={'auto'}>
                                        <Stack padding={4} spacing={4}>
                                            {getCategories(state, true)}
                                        </Stack>
                                    </Box>
                                </Stack>
                            )}

                            {state['comment'] && (
                                <Stack>
                                    <Heading size={'sm'}>{'Övrig kommentar från kunden'}</Heading>
                                    <Box padding={2} pt={0}>
                                        <Text fontSize={14}>{state['comment']}</Text>
                                    </Box>
                                </Stack>

                            )}

                            {state['offerFileToken'] && (
                                <Stack justifyContent={'space-between'}>
                                    <Heading size={'sm'}>{'Arbetsdetaljer som du skickat till kund'}</Heading>
                                    <a style={{ fontSize: 12 }} target="_blank" rel="noreferrer" href={getFirebaseImageUrl(state['offerFileToken'])}>
                                        <Button
                                            leftIcon={<Icon as={FaRegFilePdf} />}
                                            colorScheme='teal'
                                            size={'sm'}
                                            variant='link'
                                        >
                                            Öppna arbetsdetaljer
                                        </Button>
                                    </a>
                                </Stack>
                            )}
                            {state['bookingSource'] === 'mobile_app' && state['description'] && (
                                <>
                                    <Stack>
                                        <Heading size={'sm'}>Övrig kommentar från kund</Heading>
                                        <Text fontSize={14}>{state['description']}</Text>
                                    </Stack>
                                    <Divider />
                                </>
                            )}
                        </Stack>
                    </Box>
                </GridItem>
                <GridItem colSpan={3} pb={12}>
                    <Activities />
                    <Box background="white" w="100%" h={offerResult.isLoading ? 80 : 'auto'} boxShadow="md" borderRadius={'2xl'} mt={4}>
                        <Box padding="6">
                            {offerResult.isLoading ? (
                                <Spinner height={64} />
                            ) : (
                                <Stack spacing={4}>
                                    <HStack justify={'space-between'} align={'flex-start'}>
                                        <Heading pb={2} size={'sm'}>Orginalarbetet</Heading>
                                        <Icon as={FaDollarSign} color={'gray.700'} />
                                    </HStack>
                                    <Stack spacing={2}>
                                        {renderWorkDetails()}
                                    </Stack>
                                </Stack>
                            )}
                        </Box>
                    </Box>
                    {/* TODO: Fix skeleton for this */}
                    {additionalWorkOffers?.length > 0 && (
                        <Box background="white" w="100%" h={offerResult.isLoading ? 80 : 'auto'} boxShadow="md" borderRadius={'2xl'} mt={4}>
                            <Box padding="6">
                                {offerResult.isLoading ? (
                                    <Spinner height={64} />
                                ) : (
                                    <Stack spacing={4}>
                                        <HStack justify={'space-between'} align={'flex-start'}>
                                            <Heading pb={2} size={'sm'}>Accepterade tilläggsarbeten</Heading>
                                            <Icon as={FaDollarSign} color={'gray.700'} />
                                        </HStack>
                                        <Stack spacing={2}>
                                            {renderAdditionalWorkDetails()}
                                        </Stack>
                                    </Stack>
                                )}
                            </Box>
                        </Box>
                    )}

                    {totalPricingSum && (
                        <Box background="white" w="100%" boxShadow="md" borderRadius={'2xl'} mt={4}>
                            <Box padding="6">
                                {offerResult.isLoading ? (
                                    <Spinner height={64} />
                                ) : (
                                    <HStack justify={'space-between'} align={'center'}>
                                        <Heading size={'sm'}>Totalt pris för allt arbete</Heading>
                                        <Heading size={'md'} color={'green.600'}>{totalPricingSum}</Heading>
                                    </HStack>
                                )}
                            </Box>
                        </Box>
                    )}

                </GridItem>
            </ResponsiveContentWrapper>
        </BaseLayout>
    )
}

export default BookingDetails;