
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import routes from './Routes';
import RequiredAuth from "./utils/RequiredAuth";
import { SWRConfig } from 'swr';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import axios from "axios";
import { useEffect, useState } from "react";
import ScrollToTop from "./hooks/ScrollToTop";
import ReactGA from "react-ga4";

export function getTrackingId() {
  if (window.location.hostname === 'app.bilgo.se') {
    return 'G-6LWGBES82T';
  } else if (window.location.hostname === 'app-dev.bilgo.se') {
    return 'G-7FMGBP515H';
  } else {
    return 'G-GGD4NPDVVM';
  }
}

// https://reactrouter.com/docs/en/v6/getting-started/tutorial
// Gör om eventuellt Routes så det är nestlat som visat i länken
function App(): JSX.Element {

  useEffect(() => {
    ReactGA.initialize(getTrackingId());
  }, [])

  function getHost() {
    if (window.location.hostname === 'app.bilgo.se') {
      return 'AIzaSyB6kPch-QgLMto48Pu2sJawxQBxcI10jWQ'
    }
    return 'AIzaSyDcLADN0MLvytQwLIRingkKDgIbE8xpZOA'
  }

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isError) {
      (async () => {
        return axios.post(`https://securetoken.googleapis.com/v1/token?key=${getHost()}`, {
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refreshToken'),
        })
          .then((res) => {
            document.cookie = `token=${res.data.id_token}; max-age=900`
            localStorage.setItem('token', res.data.id_token)
            localStorage.setItem('refreshToken', res.data.refresh_token)
            setIsError(false)
            return;
          })
          .catch(err => {
            // Gör nått när fel kommer in, typ logga ut de eller nått
          })
      })();
    }
  }, [setIsError, isError])

  return (
    <div className="App" style={{ backgroundColor: '#fafcff', minHeight: '100vh', position: 'relative' }}>
      <SWRConfig value={{
        onErrorRetry: (error, key, config, revalidate) => {
          // Make a revalidate retry every 5 minutes when error occurs on any request - meanwhile we run the above effect updating the cookie
          setIsError(true)
          if (isError) {
            setTimeout(() => revalidate({ retryCount: 1 }), 1000 * 60 * 5) // ca 5 minuter
          }
        }
      }}>
        <Router>
          <ScrollToTop />
          <Routes>
            {routes &&
              routes.map((route, key) => {
                const { component, path, isAuthProtected } = route;
                return (
                  <Route
                    key={key}
                    path={path}
                    element={
                      <RequiredAuth isAuthProtected={isAuthProtected} route={path}>
                        {component}
                      </RequiredAuth>
                    }
                  />
                );
              })}
          </Routes>
        </Router>
      </SWRConfig>
    </div>
  );
}
/**
 * Inspo:
 * https://tailwindui.com/components/application-ui/page-examples/home-screens
 * 
 */

export default App;
