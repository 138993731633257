import useSWR from "swr"
import useSWRImmutable from 'swr/immutable'
import { Offer, OfferResult, Service, WorkArticleDetailsData } from "../../components/Marketplace/types"
import { ValueLabel } from "../types"
import { fetcher } from "./utils"


export function useAcceptedOffer(offerEnquiryId?: string, bookingId?: string) {
    const { data, error, mutate } = useSWRImmutable<OfferResult>(offerEnquiryId ? `/api/offers/offers-v1/offer-for-enquiry/${offerEnquiryId}/booking/${bookingId || ''}` : null, fetcher)

    return {
        data,
        isLoading: !error && !data && offerEnquiryId,
        isError: error,
        mutate
    }
}

export function useBookingArticleSummary(bookingId?: string | null) {
    const { data, error, mutate } = useSWRImmutable<OfferResult>(bookingId ? `/api/bookings/bookings-v1/booking-article-details/${bookingId || ''}` : null, fetcher)

    return {
        data,
        isLoading: !error && !data && bookingId,
        isError: error,
        mutate
    }

}

export function useServiceMessagesV2(bookingId?: string) {
    const { data, error, mutate } = useSWRImmutable<ServiceMessagesResponse[]>(bookingId ? `/api/communication/service-message-v2/${bookingId}` : null, fetcher)

    return {
        messages: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}


export function useBookings(query?: string) {
    const { data, error, mutate } = useSWR<Booking[]>(`/api/bookings/bookings-v1/company${query || ''}`, fetcher)

    return {
        bookings: data,
        mutateBooking: mutate,
        isLoading: !error && !data,
        isError: error,
    }
}

export function useCurrentUnfinishedBookings() {
    const { data, error, mutate, isValidating } = useSWRImmutable<Booking[]>(`/api/bookings/bookings-v1/company/current-bookings`, fetcher)

    return {
        bookings: data,
        mutateBooking: mutate,
        isLoading: !error && !data,
        isError: error,
        isValidatingBookings: isValidating,
    }
}

export function useBookingsPerYearMonth(yearMonth: string) {
    const { data, error, mutate, isValidating } = useSWR<Booking[]>(`/api/bookings/bookings-v1/company/yearmonth/${yearMonth}`, fetcher)

    return {
        bookings: data,
        mutateBooking: mutate,
        isLoadingBookings: !error && !data,
        isError: error,
        isValidatingBookings: isValidating,
    }
}

export function useBookingCountPerDayByYearMonth(yearMonth: string) {
    const { data, error, mutate, isValidating } = useSWR<Record<string, number>>(`/api/bookings/bookings-v1/company/count/yearmonth/${yearMonth}`, fetcher)

    return {
        countData: data,
        mutateBooking: mutate,
        isLoading: !error && !data,
        isError: error,
        isValidatingBookings: isValidating,
    }
}

export function useMessaging(offerId?: string) {
    const { data, error, mutate } = useSWRImmutable<OfferMessage[]>(offerId ? `/api/messaging/messaging-v1/offer/${offerId}` : null, fetcher)

    return {
        data,
        isLoading: !error && !data && offerId,
        isError: error,
        mutate
    }
}

interface OfferMessage {
    attachment: string;
    content: string;
}

export interface ServiceMessagesResponse {
    additionalWorkOffers: AdditionalWork[];
    singleMessage: any;
    files: any[];
}

interface LabelVal {
    value: string;
    label: string;
}

interface WorkCategory extends LabelVal {
    items: LabelVal[];
}
export interface AdditionalWork extends WorkArticleDetailsData {
    additionalWorkOfferId: string;
    price: number;
    selectedAction: 'accepted' | 'rejected';
    selectedAt: string;
    severityStatus: string;
    workDescription: string;
    workCategories: WorkCategory[];
}

export interface File {
    mimeType: string;
    path: string;
    fileName: string;
}

export interface Message {
    additionalWorkOffers: AdditionalWork[];
    createdAt: string;
    mimeType: string;
    path: string;
    clientId: string;
    serviceMessageId: string;
    comment: string;
    companyOpenedAt: string;
    handledAt: string;
    files: File[];
}
export interface Booking {
    acceptedDate: string;
    acceptedTime: string;
    clientId: string;
    bookingId: string;
    offerId: string;
    companyId: string;
    regNo: string;
    offerEnquiryId: string;
    offerFileToken: string;
    reparations: any[];
    service: Service,
    status: {
        key: string;
        label: string;
    };
    createdAt: string;
    phoneNo: string;
    email: string;
    fullName: string;
    description: string;
    bookingSource: 'mobile_app' | 'platform';
    selectedWorkCategories: SelectedWorkCategorieEntity[]
    messages: Message[],
    updatedAcceptedDate: string;
    pickupDate: string;
    offer: Offer;
    price: string; // This is the price when creating booking via the platform and not the one accepted from an offer enquiry
}
export interface SelectedWorkCategorieEntity {
    value: string;
    label: string;
    items: ValueLabel[];
}
