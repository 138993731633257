import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useMediaQuery, Box, Stack, Heading, Text, Image, Highlight } from "@chakra-ui/react";
import React from "react";

interface WorkOfferHelpModalProps {
    isOpenModal: boolean;
    onCloseModal: () => void;

}
const WorkOfferHelpModal: React.FC<WorkOfferHelpModalProps> = ({ isOpenModal, onCloseModal }) => {

    const [isMobile] = useMediaQuery('(max-width: 492px)')

    const renderModalBody = () => {
        return (
            <Box pb={4} pt={4}>
                <Stack spacing={8}>
                    <Stack spacing={4}>
                        <Stack spacing={2}>
                            <Heading size={'xs'}>Guide: Skicka offert till kund</Heading>
                            <Text fontSize={14}>Här visar vi ett exempel på hur du som verkstad kan skicka en offert till kunden.</Text>
                        </Stack>
                        {/* @ts-ignore */}
                        <iframe width="100%" height="460"
                            src="https://www.youtube.com/embed/MCgWgY-TTO4?controls=1&modestbranding=1&rel=0&showinfo=0"
                            title="Hello"
                            style={{ borderRadius: 20 }}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </Stack>
                </Stack>
            </Box >
        )
    }

    return (
        <>
            <Modal isOpen={isOpenModal} onClose={() => {
                onCloseModal()
            }} size={isMobile ? 'full' : '4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderModalBody()}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default WorkOfferHelpModal;
