import { Box, Button, Center, chakra, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, Icon, shouldForwardProp, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaArchive, FaBell, FaRocket, FaSadTear, FaToolbox } from 'react-icons/fa';
import { LuPartyPopper } from 'react-icons/lu';
import { motion, isValidMotionProp } from 'framer-motion';
import { Endpoints } from '../../hooks/api/endpoints';
import { axiosPut } from '../../hooks/api/utils';
import { useEvents } from '../../hooks/api/events';
import { useNavigate } from 'react-router-dom';
import { Event } from '../../hooks/types';
import noDataImage from '../../common/undraw_no_data.svg';
import { useCurrentUnfinishedBookings } from '../../hooks/api/booking';

const ChakraIcon = chakra(motion.div, {
    /**
     * Allow motion props and non-Chakra props to be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const EventTypes = {
    offer_request_created: 'offer_request_created',
    booking_created: 'booking_created', // Offer accepted basically
    additional_work_accepted: 'additional_work_accepted',
    additional_work_denied: 'additional_work_denied',
}

const eventNavigationMapper = {
    'offer_request_created': '/marketplace',
    'booking_created': '/bookings',
    'additional_work_accepted': '/',
    'additional_work_denied': '/'
}

function eventMapper(eventType: string) {
    let title;
    let description;
    let icon;
    let importance = 'medium';

    if (eventType === EventTypes.offer_request_created) {
        title = 'Inkommen offertförfrågan';
        description = 'Ny offertförfrågan i din närhet har inkommit, tryck för att visa och skicka en offert.'
        icon = FaRocket;
    }

    if (eventType === EventTypes.booking_created) {
        title = 'Din offert har accepterats';
        description = 'Hurra! Kunden har accepterat din offert och en ny bokning har skapats. Tryck för att visa bokningen.'
        icon = LuPartyPopper;
        importance = 'high'
    }

    if (eventType === EventTypes.additional_work_accepted) {
        title = 'Tilläggsarbete har accepterats';
        description = 'Hurra! Kunden har accepterat ditt erbjudande om tilläggsarbete. Tryck för att visa tilläggsarbetet.'
        icon = FaToolbox;
        importance = 'high'
    }

    if (eventType === EventTypes.additional_work_denied) {
        title = 'Tilläggsarbete har nekats';
        description = 'Kunden har valt att inte acceptera ditt erbjudande om tilläggsarbete. Tryck för att visa tilläggsarbetet.'
        icon = FaSadTear;
        importance = 'high-negative'
    }

    return { title, description, icon, importance }
}

const EventBell: React.FC = () => {
    const { mutateBooking } = useCurrentUnfinishedBookings();
    const { isOpen: isOpenEventDrawer, onOpen: onOpenEventDrawer, onClose: onCloseEventDrawer } = useDisclosure();
    const { events, mutateEvents, isValidating } = useEvents();
    const seenEventIds = events?.filter(event => !event.hasSeen).map(event => event.id);
    const notOpenedEventIds = events?.filter(event => !event.hasOpened).map(event => event.id);
    const navigate = useNavigate();
    const [isLoadingArchive, setIsLoadingArchive] = useState<number | null>(null);
    // Make some animation around the bell to be more visible
    const [highlightBell, setHightlightBell] = useState(false);

    useEffect(() => {
        if (!events) {
            return;
        }

        let timeout = null;

        timeout = setTimeout(function () {
            // Spara i session storage om ett event notifierats redan med en expiration i minuter 
            // som när den expirerar så ska vi notifiera verkstaden, kanske snarare ska göras när man väl sett meddelandena - japp troligen
            sessionStorage.setItem('latestEventsAmount', `${events.length}`)

        }, 1000)

        return function () {
            clearTimeout(timeout)
        }
    }, [isValidating, events])

    useEffect(() => {
        const prevEventAmount = parseInt(sessionStorage.getItem('latestEventsAmount'));
        if (events?.length > prevEventAmount) {
            return playSoundEffect('click-124467.mp3')
        }
        return;
    }, [events?.length, isValidating, setHightlightBell])

    useEffect(() => {
        let timeout = null;

        if (highlightBell) {
            timeout = setTimeout(function () {
                setHightlightBell(false)
            }, 60 * 2000)
        }

        return function () {
            clearTimeout(timeout)
        }

    }, [setHightlightBell, highlightBell])

    function playSoundEffect(fileLink: string) {
        setHightlightBell(true)
        var audio = new Audio(fileLink);
        audio.autoplay = true;
        audio.play();
    }

    async function handleOpenDrawer() {
        onOpenEventDrawer()
        if (seenEventIds?.length === 0) {
            return;
        }
        try {
            await axiosPut(`${Endpoints.EVENTS_V1}/seen`, seenEventIds)
            await mutateEvents();
            setupReminder(seenEventIds);
        } catch (error) {
            // Do nothing, but should inform perhaps slack as an alert            
        }
    }

    /**
     * Save to localstorage when last unseen events have been seen. This way we can make sure to send a reminder when
     * the expiration time has gone IF they for example have not opened it yet. Some events may not be as important to remind but
     * for now we remind on any event type every 2 hours if not condition such as hasOpened is not fulfilled
     * 
     * TODO: classify importance for reminders based on event type. Some event types we may want to remind more frequent if not acted on
     * TODO: Kolla om denna funkar, annars fixa
     */
    function setupReminder(seenEventIds: number[]) {
        if (seenEventIds?.length === 0) {
            return;
        }
        const expiresIn = moment.utc(new Date()).local().add(60 * 2, 'minutes').format('HH:mm:ss');
        const eventNotificationExpirationPayload = JSON.stringify({ eventIds: seenEventIds, expiresIn })
        localStorage.setItem('eventNotificationExpirationTime', eventNotificationExpirationPayload)
    }

    function getRouteBasedOnEvent(event: Event) {
        let route = eventNavigationMapper[event.eventType];
        if (event.eventType === EventTypes.offer_request_created) {
            route += `?highlight=${event.entityId}`
        }
        if (event.eventType === EventTypes.booking_created) {
            route += `?highlight=${event.entityId}`
        }
        if (event.eventType === EventTypes.additional_work_accepted) {
            route += `?highlight=${event.entityId}`
        }
        if (event.eventType === EventTypes.additional_work_denied) {
            route += `?highlight=${event.entityId}`
        }
        return route;
    }

    async function handleOpenEvent(event: Event) {
        try {
            onCloseEventDrawer()
            await mutateBooking()
            navigate(getRouteBasedOnEvent(event))

            // Avoid doing unneeded request if already set to open
            const selectedEventIsAlreadyOpened = events.find(e => e.id === event.id).hasOpened;
            if (selectedEventIsAlreadyOpened) {
                return;
            }

            await axiosPut(`${Endpoints.EVENTS_V1}/open`, { id: event.id })
            await mutateEvents();
        } catch (error) {
            // Dont do anything
        }
    }

    async function handleArchiveEvent(id: number) {
        try {
            setIsLoadingArchive(id)
            await axiosPut(`${Endpoints.EVENTS_V1}/archive`, { id })
            await mutateEvents();
            setIsLoadingArchive(null)
        } catch (error) {
            setIsLoadingArchive(null)
            // Dont do anything
        }
    }

    function renderRoundChip(val: number) {
        return <Center ml={1} w={5} h={5} bg='teal.700' fontSize={12} color="white" borderRadius={'full'}>{val}</Center>
    }

    function renderAnimatedBell() {
        return (
            <ChakraIcon
                animate={{
                    scale: [1, 1.3, 1, 1.3, 1],
                }}
                transition={{
                    duration: 4,
                    // @ts-ignore
                    repeat: Infinity,
                    repeatType: "loop",
                }}
            >
                <Center bg={'teal.100'} borderRadius={'full'} p={1}>
                    <Icon as={FaBell} color={'teal.700'} fontSize={24} _hover={{ color: 'teal.800' }} />
                </Center>
            </ChakraIcon>
        )
    }

    return (
        <Box>
            <Drawer
                isOpen={isOpenEventDrawer}
                placement='right'
                onClose={onCloseEventDrawer}
                size={'sm'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader fontSize={16}>Senaste händelserna</DrawerHeader>
                    <DrawerBody>
                        <Box mb={8}>
                            <Text fontSize={14}>Här listas senaste händelser och aktiviteter som du kan ta ställning till.</Text>
                        </Box>
                        <Tabs variant='soft-rounded' size={'sm'} p={0}>
                            <TabList gap={4} pb={4}>
                                <Tab _hover={{ color: 'teal.700' }}>Inkommande {renderRoundChip(events?.filter(event => !event.isArchived).length)}</Tab>
                                <Tab _hover={{ color: 'teal.700' }}>Arkiverade</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={0}>
                                    <Stack spacing={4}>
                                        <>
                                            {events?.filter((event) => !event.isArchived).length === 0 ? (
                                                <Center mt={12}>
                                                    <Stack spacing={6} align='center'>
                                                        <img src={noDataImage} width={140} alt="bild som visas när data saknas" />
                                                        <Text textAlign={'center'}>Inga nya händelser att visa</Text>
                                                    </Stack>
                                                </Center>
                                            ) : (
                                                <>
                                                    {events?.filter(event => !event.isArchived).map(event => {
                                                        const { title, description, icon, importance } = eventMapper(event.eventType);
                                                        return (
                                                            <Box border={importance.includes('high') ? '1px solid' : 'none'} borderColor={importance === 'high' ? 'teal.500' : importance === 'high-negative' ? 'yellow.500' : 'none'} key={event.id} onClick={() => handleOpenEvent(event)} padding={4} shadow={'base'} borderRadius={16} cursor='pointer' _hover={{ bg: 'gray.50' }}>
                                                                <HStack justifyContent={'space-between'} align='center'>
                                                                    <Heading mb={2} size="sm">{title}</Heading>
                                                                    {!event.hasOpened ? (
                                                                        <Center w={8} h={8} borderRadius='full' bg={importance === 'high-negative' ? 'yellow.100' : 'teal.100'}>
                                                                            <Icon as={icon} color={importance === 'high-negative' ? 'yellow.700' : 'teal.700'} _hover={{ color: 'white' }} />
                                                                        </Center>
                                                                    ) : (
                                                                        <Icon as={icon} color={'teal.700'} _hover={{ color: 'white' }} />
                                                                    )}
                                                                </HStack>
                                                                <Text fontWeight={!event.hasOpened ? 'bold' : 'normal'} fontSize={14}>{description}</Text>
                                                                <HStack justify={'space-between'} align='center' pt={4}>
                                                                    <Text fontWeight={!event.hasOpened ? 'bold' : 'normal'} mt={4} fontSize={12}>{moment(event.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                                    {event.hasOpened && (
                                                                        <Button isLoading={isLoadingArchive === event.id} variant={'secondary'} onClick={(e) => {
                                                                            // TODO: Lägg till kolumn för att arkivera
                                                                            e.stopPropagation();
                                                                            handleArchiveEvent(event.id);
                                                                            return;
                                                                        }} size={'sm'} rightIcon={<Icon as={FaArchive} />}>Arkivera</Button>
                                                                    )}
                                                                </HStack>
                                                            </Box>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </>
                                    </Stack>

                                </TabPanel>
                                <TabPanel p={0}>
                                    <Stack spacing={4}>
                                        {events?.filter(event => event.isArchived).map(event => {
                                            const { title, description, icon } = eventMapper(event.eventType);
                                            return (
                                                <Box key={event.id} padding={4} shadow={'base'} borderRadius={16} cursor='pointer' _hover={{ bg: 'gray.50' }}>
                                                    <HStack justifyContent={'space-between'} align='center'>
                                                        <Heading mb={2} size="sm">{title}</Heading>
                                                        <Icon as={icon} color={'teal.700'} _hover={{ color: 'white' }} />
                                                    </HStack>
                                                    <Text fontWeight={!event.hasOpened ? 'bold' : 'normal'} fontSize={14}>{description}</Text>
                                                    <HStack justify={'space-between'} align='center'>
                                                        <Text fontWeight={!event.hasOpened ? 'bold' : 'normal'} mt={4} fontSize={12}>{moment(event.createdAt, 'YYYY-MM-DDTHH:mm:ss').fromNow()}</Text>
                                                    </HStack>
                                                </Box>
                                            )
                                        })}
                                    </Stack>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
            {/* Create a component for this one to reuse on both Menus */}
            <Box onClick={() => handleOpenDrawer()} cursor={'pointer'} position={'relative'} marginRight={8}>
                <Center>
                    {notOpenedEventIds?.length > 0 && (
                        <Center zIndex={2} top={-2} right={-2} pos={'absolute'} w={4} h={4} bg={'red.600'} borderRadius={'full'} justifyContent={'center'} alignItems='center'>
                            <Text color={'white'} fontSize={14}>{notOpenedEventIds?.length}</Text>
                        </Center>
                    )}
                    {highlightBell ? (
                        renderAnimatedBell()
                    ) : (
                        <Center bg={notOpenedEventIds?.length > 0 ? 'teal.100' : 'none'} borderRadius={'full'} p={1}>
                            <Icon as={FaBell} color={'teal.700'} fontSize={24} _hover={{ color: 'teal.800' }} />
                        </Center>
                    )}
                </Center>
            </Box>
        </Box>
    )
}

export default EventBell;