import { Stack, Heading, Box, Text } from "@chakra-ui/react"
import { Booking } from "../../hooks/api/booking"
import { translateReparationSubCategories } from "../Marketplace/Reparations"
import { Service, Reparation } from "../Marketplace/types"

export const getCategories = (booking: Booking, includeReparationDescription?: boolean) => {
    return (
        <Stack spacing={4}>
            {getReparationsCagetory(booking.reparations, includeReparationDescription)}
            {getServiceCategory(booking.service)}
        </Stack>
    )
}

export const getServiceCategory = (serviceObject: Service) => {
    if (serviceObject && serviceObject?.selectedServiceCategories) {
        return (
            <Stack spacing={0}>
                <Heading size={'xs'}>Service och underhåll</Heading>
                {serviceObject?.selectedServiceCategories?.map((category, i) => (
                    <Box key={i} pl={6}>
                        <ul>
                            <li>
                                <Text fontWeight={'normal'} fontSize={14}>{category.label}</Text>
                            </li>
                        </ul>
                    </Box>
                ))}
            </Stack>
        )
    }
}

export const getReparationsCagetory = (reparations: Reparation[], includeReparationDescription?: boolean) => {
    if (reparations && reparations.length > 0) {
        return (
            <Stack spacing={4}>
                {reparations?.map((reparation, i) => {
                    return (
                        <Stack key={i}>
                            <Heading size={'xs'}>{translateReparationSubCategories(reparation.category)}</Heading>
                            {reparation?.workOptions && (
                                <Stack spacing={0} pb={2}>
                                    {reparation?.workOptions?.map(option => {
                                        return (
                                            <Text fontSize={14}>{option.name}</Text>
                                        )
                                    })}
                                </Stack>
                            )}
                            {includeReparationDescription && reparation.description && (
                                <Box bg={'white'} borderColor={'gray.200'} borderWidth={1} p={1.5} px={2.5} borderRadius={8} maxW={96}>
                                    <Text fontSize={14}>{reparation.description}</Text>
                                </Box>
                            )}
                        </Stack>
                    )
                })}
            </Stack>
        )
    }
}