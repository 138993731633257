import { HStack, Stack, Heading, Box } from '@chakra-ui/react'
import React from 'react'

interface LicensePlateProps {
    regNo: string;
    size?: 'xs' | 'sm' | 'md' | 'lg',
    variant?: 'outline' | 'full'
}

const LicensePlate: React.FC<LicensePlateProps> = ({ regNo, size = 'md', variant = 'full' }) => {
    if (!regNo) {
        return null;
    }

    const sizeMapping = {
        xs: {
            containerWidth: 120,
            bluePartWidth: 8,
            bluePartHeight: 8,
            plateSize: 'xs',
        },
        sm: {
            containerWidth: 120,
            bluePartWidth: 8,
            bluePartHeight: 10,
            plateSize: 'sm',
        },
        md: {
            containerWidth: 140,
            bluePartWidth: 10,
            bluePartHeight: 12,
            plateSize: 'md',
        },
        lg: {
            containerWidth: 160,
            bluePartWidth: 12,
            bluePartHeight: 14,
            plateSize: 'md',
        }
    }

    const style = sizeMapping[size];

    return (
        <HStack background={'white'} borderRadius={'md'} border={variant === 'full' ? '1px solid gray' : 'none'} display={'inline-flex'} width={style.containerWidth} justifyContent={'center'}>
            <Stack borderLeftRadius={'md'} justifyContent={'center'} alignItems={'center'} width={style.bluePartWidth} height={style.bluePartHeight} background={'blue'} >
                <Heading size={'sm'} color={'white'}>S</Heading>
            </Stack>
            <HStack justifyContent={'center'} width={style.containerWidth}>
                <Box mr={2}>
                    <Heading size={style.plateSize}>{regNo}</Heading>
                </Box>
            </HStack>
        </HStack>
    )
}

export default LicensePlate;